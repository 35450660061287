var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.RJ=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.SJ=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.TJ=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var UJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.ps],null)),VJ=null,WJ=0,XJ=0;;)if(XJ<WJ){var fha=VJ.X(null,XJ);$CLJS.yF(fha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)],null)],null)]));XJ+=1}else{var YJ=$CLJS.y(UJ);if(YJ){var ZJ=YJ;if($CLJS.Ad(ZJ)){var $J=$CLJS.lc(ZJ),gha=$CLJS.mc(ZJ),
hha=$J,iha=$CLJS.D($J);UJ=gha;VJ=hha;WJ=iha}else{var jha=$CLJS.A(ZJ);$CLJS.yF(jha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)],null)],null)]));UJ=$CLJS.B(ZJ);VJ=null;WJ=0}XJ=0}else break}$CLJS.wF($CLJS.rs,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)]));
for(var aK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.yG],null)),bK=null,cK=0,dK=0;;)if(dK<cK){var kha=bK.X(null,dK);$CLJS.yF(kha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null)],null)]));dK+=1}else{var eK=$CLJS.y(aK);if(eK){var fK=eK;if($CLJS.Ad(fK)){var gK=$CLJS.lc(fK),lha=$CLJS.mc(fK),
mha=gK,nha=$CLJS.D(gK);aK=lha;bK=mha;cK=nha}else{var oha=$CLJS.A(fK);$CLJS.yF(oha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null)],null)]));aK=$CLJS.B(fK);bK=null;cK=0}dK=0}else break}
for(var hK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Qr,$CLJS.Sr,$CLJS.Mr,$CLJS.Or],null)),iK=null,jK=0,kK=0;;)if(kK<jK){var pha=iK.X(null,kK);$CLJS.wF(pha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));kK+=1}else{var lK=$CLJS.y(hK);if(lK){var mK=lK;if($CLJS.Ad(mK)){var nK=$CLJS.lc(mK),qha=$CLJS.mc(mK),rha=nK,sha=$CLJS.D(nK);hK=qha;iK=rha;jK=sha}else{var tha=$CLJS.A(mK);$CLJS.wF(tha,$CLJS.H([$CLJS.it,$CLJS.Jj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));hK=$CLJS.B(mK);iK=null;jK=0}kK=0}else break}$CLJS.wF($CLJS.KF,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));
$CLJS.wF($CLJS.QF,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));
for(var oK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.UF],null)),pK=null,qK=0,rK=0;;)if(rK<qK){var uha=pK.X(null,rK);$CLJS.wF(uha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)]));rK+=1}else{var sK=$CLJS.y(oK);if(sK){var tK=sK;if($CLJS.Ad(tK)){var uK=$CLJS.lc(tK),vha=$CLJS.mc(tK),wha=uK,xha=$CLJS.D(uK);oK=vha;pK=wha;qK=xha}else{var yha=$CLJS.A(tK);$CLJS.wF(yha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)]));oK=$CLJS.B(tK);
pK=null;qK=0}rK=0}else break}
for(var vK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.SF],null)),wK=null,xK=0,yK=0;;)if(yK<xK){var zha=wK.X(null,yK);$CLJS.wF(zha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)]));yK+=1}else{var zK=$CLJS.y(vK);if(zK){var AK=zK;if($CLJS.Ad(AK)){var BK=$CLJS.lc(AK),Aha=$CLJS.mc(AK),Bha=BK,Cha=$CLJS.D(BK);vK=Aha;wK=Bha;xK=Cha}else{var Dha=$CLJS.A(AK);$CLJS.wF(Dha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)]));vK=$CLJS.B(AK);
wK=null;xK=0}yK=0}else break}
for(var CK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null),DK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ZF,$CLJS.sG,$CLJS.gG,$CLJS.$F],null)),EK=null,FK=0,GK=0;;)if(GK<FK){var HK=EK.X(null,GK);$CLJS.HG.v(HK,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,HK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.wE,CK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.fF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null));GK+=1}else{var IK=$CLJS.y(DK);if(IK){var JK=IK;if($CLJS.Ad(JK)){var KK=$CLJS.lc(JK),Eha=$CLJS.mc(JK),Fha=KK,Gha=$CLJS.D(KK);DK=Eha;EK=Fha;FK=Gha}else{var LK=$CLJS.A(JK);$CLJS.HG.v(LK,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,LK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.wE,CK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.fF],null)],null));DK=$CLJS.B(JK);EK=null;FK=0}GK=0}else break}
$CLJS.HG.v($CLJS.tG,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.tG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.wE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.Sv,$CLJS.Ux,$CLJS.PF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)],null));$CLJS.HG.v($CLJS.GF,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.GF],null),$CLJS.wE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.jE,$CLJS.rE],null)],null));
$CLJS.X($CLJS.RJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.SJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ms,$CLJS.rk,$CLJS.yG,$CLJS.QF,$CLJS.KF,$CLJS.Qr,$CLJS.Mr,$CLJS.Sr,$CLJS.Or,$CLJS.JF,$CLJS.UF,$CLJS.LF,$CLJS.SF,$CLJS.gG,$CLJS.$F,$CLJS.ZF,$CLJS.sG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TJ,$CLJS.Wi],null)],null));