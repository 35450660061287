var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Z0,Ona,Pna,$0,a1;$CLJS.X0=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};Z0=function(a){return $CLJS.n(Y0)?["(",$CLJS.p.h(a),")"].join(""):a};Ona=function(a,b,c){var d=$CLJS.DW(a,b);a=$CLJS.J0.j(a,b,d);var e=$CLJS.XA(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.XA($CLJS.T.h(f)),e)},a)};
Pna=function(a,b){var c=$CLJS.Rk.v(a,$CLJS.YP,$CLJS.TD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.RE.h(a))?$CLJS.Rk.v(c,$CLJS.RE,$CLJS.be,$CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,$CLJS.N,$CLJS.wW(b)],null))):c};$0=function(a,b,c){var d=$CLJS.wW(c);return $CLJS.R.l($CLJS.Y_.j(a,b,c),$CLJS.PK,$CLJS.CZ,$CLJS.H([$CLJS.T,d,$CLJS.mE,d]))};a1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.b1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var c1=function(){function a(d,e,f){var k=$CLJS.DW(d,e);k=$CLJS.uH($CLJS.Ok.g($CLJS.ch([f]),$CLJS.wW),$CLJS.YP.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.hE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.$R,f,$CLJS.EF,d,$CLJS.CL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.C0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=c1.j(a,b,c);return $CLJS.D0.j(a,b,c)});$CLJS.E0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Xt,$CLJS.NK,$CLJS.b1,$CLJS.yE.h(d),$CLJS.T,e,$CLJS.qE,e,$CLJS.mE,$CLJS.Z_.j(a,b,c),$CLJS.pB,$CLJS.D0.j(a,b,c),$CLJS.PK,$CLJS.CZ],null)});$CLJS.y0.m(null,$CLJS.hB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.y0.m(null,$CLJS.fB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.y0.m(null,$CLJS.gB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.y0.m(null,$CLJS.xB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.y0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.z0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var Y0=!1,Qna=new $CLJS.h(null,4,[$CLJS.Ur,"+",$CLJS.it,"-",$CLJS.Vr,"×",$CLJS.CG,"÷"],null),d1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ur,$CLJS.it,$CLJS.CG,$CLJS.Vr],null)),e1=null,f1=0,g1=0;;)if(g1<f1){var Rna=e1.X(null,g1);$CLJS.pF(Rna,a1);g1+=1}else{var h1=$CLJS.y(d1);if(h1){var i1=h1;if($CLJS.Ad(i1)){var j1=$CLJS.lc(i1),Sna=$CLJS.mc(i1),Tna=j1,Una=$CLJS.D(j1);d1=Sna;e1=Tna;f1=Una}else{var Vna=$CLJS.A(i1);$CLJS.pF(Vna,a1);d1=$CLJS.B(i1);e1=null;f1=0}g1=0}else break}
$CLJS.y0.m(null,a1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Qna,c);a:{var e=Y0;Y0=!0;try{var f=$CLJS.At([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.$t($CLJS.Z_,a,b),d));break a}finally{Y0=e}f=void 0}return Z0(f)});$CLJS.z0.m(null,a1,function(){return"expression"});
$CLJS.C0.m(null,$CLJS.BH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.rF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.D0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.D0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.y0.m(null,$CLJS.iJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.Z_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.R0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.xA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=Z0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.z0.m(null,$CLJS.iJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.A0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.R0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.xA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.y0.m(null,$CLJS.kG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.Z_.v(a,b,e,d)});$CLJS.z0.m(null,$CLJS.kG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.A0.j(a,b,d)});
$CLJS.Wna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Ona(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.$R,f],null));return $CLJS.OW.l(d,e,Pna,$CLJS.H([$CLJS.xW($CLJS.pW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Xna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.YP.h($CLJS.DW(d,e)));return null==f?null:$CLJS.Qk.g($CLJS.$t($0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.k1=function(){function a(d,e){return $CLJS.Fe($CLJS.YP.h($CLJS.DW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.mW.m(null,$CLJS.mB,function(a){return a});
$CLJS.Yna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.cm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wW(t),m],null)},$CLJS.k1.g(d,e))),l=$CLJS.DW(d,e);d=$CLJS.J0.j(d,e,l);return $CLJS.Fe($CLJS.X0(function(m){return $CLJS.Va(f)||$CLJS.Mk.g($CLJS.PK.h(m),$CLJS.CZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Zna=function(){function a(d,e,f){return $CLJS.oW($0(d,e,c1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();