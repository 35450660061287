var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var OI,QI,SI,dJ,hJ,pJ;$CLJS.NI=new $CLJS.M(null,"get-month","get-month",-369374731);OI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.PI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);QI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.RI=new $CLJS.M(null,"iso","iso",-1366207543);SI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.TI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.UI=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.VI=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.WI=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.XI=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.YI=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.ZI=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.$I=new $CLJS.M(null,"us","us",746429226);$CLJS.aJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.bJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.cJ=new $CLJS.M(null,"now","now",-1650525531);dJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.eJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.fJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.gJ=new $CLJS.M(null,"get-week","get-week",-888059349);
hJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.iJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.jJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.kJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.lJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.mJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.nJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.oJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);pJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.qJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.rJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.sJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.tJ=new $CLJS.M(null,"target","target",253001721);$CLJS.wF($CLJS.ZI,$CLJS.H([$CLJS.it,$CLJS.cD,$CLJS.Bs,$CLJS.bH]));$CLJS.LE.m(null,$CLJS.WI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.ME(a);return $CLJS.vd(a)?(a=$CLJS.fu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.SC,null,$CLJS.rD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.pF($CLJS.WI,$CLJS.bF);
for(var uJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,$CLJS.oJ],null)),vJ=null,wJ=0,xJ=0;;)if(xJ<wJ){var yJ=vJ.X(null,xJ);$CLJS.wF(yJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null),$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)]));$CLJS.pF(yJ,$CLJS.WI);xJ+=1}else{var zJ=$CLJS.y(uJ);if(zJ){var AJ=zJ;if($CLJS.Ad(AJ)){var BJ=$CLJS.lc(AJ),Tga=$CLJS.mc(AJ),Uga=BJ,Vga=$CLJS.D(BJ);uJ=Tga;vJ=Uga;wJ=Vga}else{var CJ=$CLJS.A(AJ);$CLJS.wF(CJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.UE],null),$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)]));$CLJS.pF(CJ,$CLJS.WI);uJ=$CLJS.B(AJ);vJ=null;wJ=0}xJ=0}else break}
for(var DJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UI,$CLJS.NI,$CLJS.fJ,$CLJS.nJ,$CLJS.jJ,$CLJS.TI,$CLJS.qJ],null)),EJ=null,FJ=0,GJ=0;;)if(GJ<FJ){var Wga=EJ.X(null,GJ);$CLJS.wF(Wga,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));GJ+=1}else{var HJ=$CLJS.y(DJ);if(HJ){var IJ=HJ;if($CLJS.Ad(IJ)){var JJ=$CLJS.lc(IJ),Xga=$CLJS.mc(IJ),Yga=JJ,Zga=$CLJS.D(JJ);DJ=Xga;EJ=Yga;FJ=Zga}else{var $ga=$CLJS.A(IJ);$CLJS.wF($ga,$CLJS.H([$CLJS.it,
$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));DJ=$CLJS.B(IJ);EJ=null;FJ=0}GJ=0}else break}$CLJS.wF($CLJS.YI,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZG],null)]));
for(var KJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.qJ,null,$CLJS.jJ,null,$CLJS.nJ,null,$CLJS.kJ,null,$CLJS.TI,null,$CLJS.NI,null,$CLJS.UI,null,$CLJS.fJ,null],null),null)),LJ=null,MJ=0,NJ=0;;)if(NJ<MJ){var aha=LJ.X(null,NJ);$CLJS.wF(aha,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));NJ+=1}else{var OJ=$CLJS.y(KJ);if(OJ){var PJ=OJ;if($CLJS.Ad(PJ)){var QJ=$CLJS.lc(PJ),bha=$CLJS.mc(PJ),cha=QJ,dha=$CLJS.D(QJ);
KJ=bha;LJ=cha;MJ=dha}else{var eha=$CLJS.A(PJ);$CLJS.wF(eha,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));KJ=$CLJS.B(PJ);LJ=null;MJ=0}NJ=0}else break}$CLJS.X(pJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.RI,$CLJS.$I,$CLJS.eJ],null));
$CLJS.yF($CLJS.gJ,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.lJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.rE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,2,[$CLJS.pt,"valid timezone ID",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.TG($CLJS.Jd,$CLJS.MI.tz.names())),$CLJS.DI],null)],null));
$CLJS.yF($CLJS.PI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)],null)],
null)]));$CLJS.pF($CLJS.PI,$CLJS.WI);$CLJS.wF($CLJS.cJ,$CLJS.H([$CLJS.it,$CLJS.VC]));$CLJS.X(SI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.rC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SC,$CLJS.rD],null))}],null)],null));
$CLJS.X(OI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SI],null)],null)],null)],null));
$CLJS.HG.g($CLJS.VI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.pt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.VI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.Sv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WG],null)],null)],null)],null)],null)],null));
$CLJS.LE.m(null,$CLJS.VI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Sv)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.rD:$CLJS.n($CLJS.oF($CLJS.aH,a))?$CLJS.SC:$CLJS.rD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.yI,b))?$CLJS.SC:$CLJS.n($CLJS.nh($CLJS.zI,b))?$CLJS.SC:null:null;if($CLJS.n(a))return a;b=$CLJS.ME(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.rC(c,$CLJS.Uj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(hJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,$CLJS.Sv],null),$CLJS.Bs],null));
$CLJS.yF($CLJS.aJ,$CLJS.H([$CLJS.it,$CLJS.rD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)],null)],null)],null)]));
$CLJS.wF($CLJS.Ey,$CLJS.H([$CLJS.it,$CLJS.RD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$G],null)]));$CLJS.X(QI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ms,$CLJS.YG,$CLJS.zi,$CLJS.kk,$CLJS.XI,$CLJS.rJ,$CLJS.sJ,$CLJS.Ej,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.VG],null));$CLJS.X(dJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.RI,$CLJS.$I,$CLJS.eJ],null));
$CLJS.yF($CLJS.mJ,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dJ],null)],null)],
null)],null)]));